<template>
  <div class="protocol">
    <h3 class="title">用户协议</h3>
    <p class="right">更新于 2021 年 12 月 28 日</p>
    <p class="content">欢迎您使用口袋时间软件及相关服务！</p>
    <p class="content">
      厦门逆为网络科技有限公司（含其关联公司，以下称“逆为”或“我们”）为口袋时间
      app（以下称“本站”或“口袋时间”）的实际开发者和运营者。“口袋时间”是一款专注放松、减压睡眠的身心健康app，用户可以跟随“口袋时间”的声音场景进行专注、放松、减压或助眠活动。本服务条款构成我们与本站用户（以下称“用户”或“您”）之间就口袋时间app提供的上述服务等事宜订立的契约，请仔细阅读本服务条款。
    </p>
    <p class="content">
      您访问本站，下载口袋时间app并注册成为本站用户后，则即表示您同意接受本服务条款的约束，而本服务条款即构成对双方具有约束力的法律文件。
    </p>
    <p class="bold">一、服务条款的确认及接受</p>
    <p class="content">
      本站的各项电子服务的所有权和运作权归逆为所有。您同意服务条款及隐私条款并完成注册程序后，即成为本站的正式用户。用户确认：本服务条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
    </p>
    <p class="content">
      请您仔细阅读本协议全部条款，并确认您已完全了解本协议之规定。
      除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束，本协议将构成您与厦门逆为网络科技有限公司（及关联方）与其主办的口袋时间APP合称为“口袋时间”）之间直接有约束力的法律文件。
    </p>
    <p class="content">
      未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意。
      请您审慎阅读并选择接受或不接受本协议。
    </p>
    <p class="content">
      逆为保留在中华人民共和国大陆地区法现行法律法规允许的范围内自行决定拒绝服务、关闭用户帐号、清除或编辑内容或取消订单等的权利。
    </p>
    <p class="content">
      本站的软件著作权及 App
      上发布的所有信息与内容的版权均受中华人民共国著作权法（含相关法律、法规）乃至世界版权法律的保护，仅供用户因自身学习、调研等目的免费使用。严禁未经授权对本站内容进行复制、发行、出租、展览、放映、广播、修改、演绎、翻译、汇编、改编或逆向工程。厦门逆为网络科技有限公司保留所有未在本服务条款中明确授予的权利。
    </p>
    <p class="content">
      逆为通过互联网依法为用户提供互联网信息等服务，您在完全同意本服务条款及本站其他规定（包括但不限于隐私条款、社区公约、社区规范、使用提示、退换货政策等在本站发布的所有规则及政策）的情况下，方有权使用本站的相关服务。
    </p>
    <p class="content">用户必须自行准备如下设备并承担如下开支：</p>
    <p class="content">
      上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
    </p>
    <p class="content">
      您同意，仅以非商业目的使用本站；不对本站的任何部分或全部进行商业性质利用、复制、拷贝、出售、调查、广告，或将本站用于其他任何商业目的或商业性质的活动；但逆为与您另行签订有协议或逆为另行指定可供您使用的特定商业服务除外。
    </p>
    <p class="bold">二、本站帐号注册规则</p>
    <p class="content">
      本站帐号注册资料包括但不限于用户的帐号名称、头像、密码、注册或更新帐号时输入的所有信息以及您在本站商城进行购物时所填写的所有信息（包括但不限于收货人姓名、地址、联系电话等）。
    </p>
    <p class="content">
      在注册本站帐号时，用户应承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，且保证在注册及使用本站帐号时，不得有以下情形：
    </p>
    <p class="content">违反宪法或法律法规规定的；</p>
    <p class="content">
      危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
      损害国家荣誉和利益的，损害公共利益的；<br />
      煽动民族仇恨、民族歧视，破坏民族团结的；<br />
      破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
      散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
      散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
      侮辱或者诽谤他人，侵害他人合法权益的；<br />
      含有法律、行政法规禁止的其他内容或违反逆为的其他规定的。<br />
      用户应保证其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新。因用户提供的注册资料不符合本条款约定而引起的一切责任及后果（包括但不限于不予注册、帐号停用或注销等），由用户自行承担，且逆为保留终止用户使用逆为各项服务的权利。
    </p>
    <p class="content">
      用户以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法和不良信息的，逆为有权采取通知限期改正、暂停使用、注销登记等措施。
    </p>
    <p class="content">
      对冒用、关联机构或社会名人注册帐号名称的用户，逆为有权注销其帐号，并向互联网信息内容主管部门报告。逆为有权根据自己的判定，在怀疑帐号被不当使用时，拒绝帐号使用或限制帐号使用或注销该帐号。
      如您连续180天未以帐号登录本站，则本站有权根据自己的判定，注销该帐号且无需事先向您发送通知
    </p>
    <p class="content">
      用户在使用本站服务或下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露。
    </p>
    <p class="content">
      用户成功注册后，将产生用户名和密码等帐号信息，您可以根据本站规定更改您的密码。用户应谨慎合理的保存、使用其用户名和密码，并对任何人利用您的帐号及密码所进行的活动负完全的责任。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通知本站并向公安机关报案。因用户未妥善保管其帐号名称及密码而导致第三人使用帐号而给用户造成的损失，由用户自行承担。
    </p>
    <p class="content">
      您的本站帐号仅限于您自己使用，严禁将您的本站帐号允许第三人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。您应了解，在帐号和密码匹配时，本站无法对非法或未经您授权使用您帐号及密码的行为作出甄别，因此，本站对任何使用您帐号和密码登录并使用本站的行为不承担任何责任。
    </p>
    <p class="content">
      依据相关法律法规的规定，逆为有权对注册用户进行基于移动电话号码、电子邮箱、微信等真实身份信息认证。对于拒绝进行真实身份信息认证的用户，逆为保留拒绝此用户使用本站相关服务的权利并有权对用户进行包括但不限于限制服务使用、关闭帐号等的处理。
    </p>
    <p class="bold">三、用户隐私制度</p>
    <p class="content">
      用户知悉并同意，为了记录用户使用的真实数据以及在电商功能下给用户提供配送等相关服务，逆为在您自愿的前提下收集您的个人信息并将这些信息进行整合，包括但不限于注册帐号时的相关信息、个人身体数据（身高、体重、健康数据等）、GPS定位数据、收货人的姓名、地址、电话、所处位置信息、个人说明、手机软硬件信、手机识别码等。我们承诺，不以非法方式披露您依照我们规定的方式标明应予保密的用户个人信息。
    </p>
    <p class="content">
      尊重用户个人隐私是本站的一项基本政策。所以，本站不会公开或向任何第三方透露用户的注册资料以及用户因享受本站服务而提供的任何信息，但以下情形除外：
    </p>
    <p class="content">
      事先获得用户的明确授权或许可；<br />
      遵守有关法律规定，包括在国家有关机关查询时，提供用户的注册信息、用户在本站发布的信息内容及其发布时间、互联网地址或者域名等；<br />
      在紧急情况下竭力维护用户个人和社会大众的隐私安全；<br />
      为维护本站的著作权、商标权、专利权及其他任何合法权利或权益；<br />
      在进行促销或抽奖时，本站可能会与赞助商共享用户的个人信息，在这些情况下“本站”会在发送用户信息之前进行提示，并且用户可以通过明确表示不参与活动而终止传送过程。<br />
      将用户数据用于统计，以便向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述本站的服务；<br />
      根据本条款相关规定或者本站认为必要的其他情形。<br />
      本站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站可将用户信息提供给该第三方。
    </p>
    <p class="bold">四、服务方式</p>
    <p class="content">
      除免费服务外，本站可能提供部分收费服务。您可以通过付费方式购买收费服务，具体方式为：通过网上银行或其他本站提供的付费途径支付一定数额的人民币，从而获得收费服务使用权限。对于收费服务，本站会在您使用之前给予明确的提示，只有根据提示确认其同意按照前述支付方式支付费用并完成了支付行为，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。
    </p>
    <p class="content">
      您应了解本站可能对该等服务（含收费服务）进行更改、更新或提高，并承诺不因本站更改、更新或提高其服务给您造成的损失或不便而向我们要求赔偿或补偿。
    </p>
    <p class="content">
      商品或服务价格、数量、性能介绍、是否有货等商品信息随时都有可能发生变动，本站不作特别通知，请用户至商品或服务销售页面自行查看。我们会尽最大努力保证您所浏览的商品信息的准确性，但由于商品种类繁多且商品信息的数量较大，加之互联网技术因素等客观原因存在，本站显示的信息可能会有一定的滞后性或差错，对此情形请您知悉并理解。我们欢迎纠错，并会视情况给予纠错者一定的奖励。
    </p>
    <p class="content">
      为回馈用户的支持，我们会不定期向用户发放一些优惠券或代金券，上述优惠券或代金券的发放数量及规则由我们自行决定，且由本站享有解释权和修改权。您有权按照优惠券或代金券的使用规则在本站购物时的使用，严禁转卖本站优惠券或代金券或利用本站帐号进行其他经营性行为等。
    </p>
    <p class="content">
      若您在商品购买过程中遇到任何问题，均可发送邮件至
      service@nilwy.com，我们的客服人员会及时与您取得联系并帮助您解决问题。
    </p>
    <p class="content">
      您同意，遵守法律及本协议规定，秉承善意使用本站，且保证：
    </p>
    <p class="content">
      不会利用本站进行非法活动、或进行侵犯他人权利或损害他人利益的活动；不会以非法方式获取或利用本站其他用户的信息。
    </p>
    <p class="content">
      不会以技术方式攻击或破坏或改变本站的部分或全部、或干扰其运行；不会以非法方式获取或使用本站的任何软件、代码或其他技术或商业信息；不会对本站运行的任何程序进行反向工程、反向编译、反向汇编或改写。
    </p>
    <p class="content">
      不会未经许可使用本站的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由逆为（及关联方）或本站享有知识产权或权利的信息或资料；不会侵犯逆为（及关联方）或本站的商标权、著作权、专利权、其他知识产权或其他合法权利或权益。不会以任何方式贬损本站的商业声誉。
    </p>
    <p class="content">不会利用本站进行其他违背公序良俗的活动。</p>
    <p class="content">
      您使用本站的记录，可能被保存作为对您不利的证据。本站也可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门、司法机关。
    </p>
    <p class="bold">五、所有权及知识产权条款</p>
    <p class="content">
      用户一旦接受本服务条款，即表明该用户主动将其在本站发表的任何形式的信息内容（包括但不限于用户评论、用户咨询、各类话题文章、声音和图片等）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利）等，全部独家且不可撤销地转让给逆为所有，用户同意逆为有权就任何主体侵权而单独提起诉讼。
    </p>
    <p class="content">
      本服务条款已经构成《中华人民共和国著作权法》第二十五条及相关法律规定的著作财产权等权利转让的书面协议，其效力及于用户在本站发布的任何受著作权法保护的作品内容，无论该等内容形成于本服务条款订立前还是订立后。
    </p>
    <p class="content">
      用户同意并已充分了解本条款的含义，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。
    </p>
    <p class="content">
      逆为（含其关联企业）是本站的所有者及运营者，拥有本站内容、资源及软件的著作权等合法权利，受国家法律保护，有权不时地对本服务条款、本站版本及内容进行修改或更新，并在App内张贴或直接更新，无须另行通知用户。在法律允许的最大限度范围内，逆为（含其关联企业）对本条款及本站内容拥有解释权。
    </p>
    <p class="content">
      除法律另有强制性规定外，未经逆为（含其关联企业）明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站及本站内的信息内容，否则，逆为有权追究其法律责任。
    </p>
    <p class="content">
      本站及本站app
      内所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、
      数字下载、数据编辑和软件）和本站及本站app
      的本身自带的所有版本、技术、程序、素材和所有的信息内容（包括但不仅限于设计图样、产品功能、用户界面、操作步骤、商标、专利、计算机软件作品以及其他图样、产品及服务名称），均是逆为或其内容提供者的财产，受中华人民共和国和国际版权法及相关法律法规的保护；上述内容的汇编是逆为的排他财产，受中华人民共和国和国际版权法及相关法律法规的保护；本站上所有软件都是逆为（含其关联公司）的财产，受中华人民共和国和国际版权法及相关法律法规的保护
    </p>
    <p class="content">
      经由本站传送的文字、图片、音频、视频、素材（指由逆为提供的已经内置于本站的或者未来即将内置的包括但不限于音频、字体、文字、说明、动画等用于帮助您睡眠、放松等的相关素材与信息）及其他内容，受到著作权法、商标法、专利法或其他法律的保护；未经厦门逆为网络科技有限公司书面授权许可，您不得自行或许可第三方进行修改、出租、售卖或衍生其他作品。逆为对其专有内容、原创内容和其他通过授权取得的独占或者独家内容享有知识产权。未经逆为书面许可，任何单位和个人不得私自转载、传播和提供收听服务或者有其他侵犯逆为知识产权的行为。否则，将承担法律责任。
    </p>
    <p class="bold">六、责任限制及不承诺担保</p>
    <p class="content">
      除非另有明确的书面说明，本站及其所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。
    </p>
    <p class="content">
      除非另有明确的书面说明，我们不对本站的运营及其包含在本站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
    </p>
    <p class="content">
      我们不担保本站所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。
    </p>
    <p class="content">
      如因不可抗力或其它本站无法控制的原因使本站服务系统崩溃或无法正常使用导致服务不可用或网上交易无法完成或丢失有关的信息、记录等，我们会合理地尽力协助处理善后事宜。
    </p>
    <p class="content">
      您应了解，基于不同用户的个人特征和喜好，本站可能存在一些让您不快、厌恶或难以接受的内容。您承诺，不就前述内容追究我们或第三方的任何责任。
    </p>
    <p class="content">
      本站尽可能保证其稳定运行。您应理解并同意，因法律、政策、技术、经济、管理的原因，除非您和本站另有约定，本站不会因以下情形出现而对您承担责任：
    </p>
    <p class="content">
      本站无法使用或中断使用或无法完全适合用户的使用要求；<br />
      本站受到干扰，无法及时、安全、可靠运行，或出现任何错误；<br />
      经由本站取得的任何产品、服务（含收费服务）或其他材料不符合您的期望；<br />
      用户资料遭到未经授权的使用或修改。<br />
      本站不对您在本本协议项下服务中相关数据的删除或储存失败负责。本站可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。如您停止使用本站，或本站所提供的服务终止或中止，本站可以从服务器上永久地删除用户的数据。本站没有义务向用户返还任何数据。
    </p>
    <p class="content">
      本站对不可抗力导致的损失不承担责任。本服务条款所指不可抗力包括：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
    </p>
    <p class="content">
      本协议或本协议项下本站所提供的服务（含收费服务）可在下述情形下部分或全部中止或终止：
    </p>
    <p class="content">
      因法律规定，或本站服从行政命令或司法判令的要求；<br />
      用户违反本协议；<br />
      本站认为应予终止的其他情形。<br />
      在一般情形下，本站会提前按照您提供的联系方式以电子邮件或短信或其他电子方式通知您，服务将中止或终止。您应了解并同意，在紧急情况或特殊下，本站可不经通知即中止或终止服务。
    </p>
    <p class="content">
      在本协议或本协议项下本站提供的服务（含收费服务）中止或终止时，本站有权：
    </p>
    <p class="content">
      拒绝用户登录本站；<br />
      删除用户信息；<br />
      删除用户购买的内容。<br />
      您同意，不因本协议或本协议项下本站所提供的服务中止或终止，而要求我们或本站向您作出赔偿或补偿或承担任何其他责任。
    </p>
    <p class="content">
      为方便您使用，本站可能会提供第三方国际互联网网站或资源链接。您应了解，除非另有声明，本站无法对第三方网站服务进行控制。您因下载或传播或使用或依赖上述网站或资源所生的损失或损害，应由您自行承担全部责任。
    </p>
    <p class="bold">七、协议更新及用户关注义务</p>
    <p class="content">
      根据国家法律法规的更新及网站运营需要，我们有权对本条款不时地进行修改，修改后的服务条款一旦被张贴在本站上即生效力，并代替原来的服务条款。用户可随时登录查阅最新服务条款内容。如用户不同意更新后的服务条款，应立即停止接受本站提供的服务；如用户继续使用本站提供的服务，即视为同意更新后的服务条款。我们建议您在使用本站之前阅读本服务条款及本站的公告。如果本服务条款中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
    </p>
    <p class="bold">八、法律管辖和适用</p>
    <p class="content">
      本服务条款的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区现行之有效法律。
      如发生本服务条款的某些内容与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本条款内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。仲裁裁决是终局的，对双方都有约束力。仲裁费用由败诉一方承担。
    </p>
    <p class="bold">九、其他</p>
    <p class="content">
      本站的所有者是指在政府部门依法许可或备案的本站本站及本站 app 的经营主体。
    </p>
    <p class="content">
      我们尊重用户和消费者的合法权利，本服务条款及本站上发布的各类规则、政策、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，您可以通过以下电子邮箱联系我们：talk@moreless.io，我们将虚心接受并适时修改本服务条款及相关政策、规则。
    </p>
    <p class="content">
      您同意，如因您违反法律规定或本协议规定给本站造成损失，您将充分赔偿我们所遭受的损失、包括其直接损失、间接损失、预期利益损失等一切损失。您承诺，如因第三方向您主张权利而导致您承担责任，或您承担行政责任或刑事责任的，您不以此为理由追究本站的责任。如您应向本站承担责任，前述责任不因本协议或本协议项下的服务被终止而免除。
    </p>
    <p class="content">
      本协议内容中以黑体、加粗等方式显著标识的条款，请用户着重阅读。
    </p>
    <p class="bold">厦门逆为网络科技有限公司</p>
  </div>
</template>

<script>
export default {
  name: 'Protocol'
}
</script>

<style lang="less" scoped>
.protocol {
  padding: 15px;
  .right {
    text-align: right;
  }
  .title {
    font-size: 20px;
    color: #333;
    text-align: center;
    line-height: 18px;
    padding: 10px;
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 23px;
    margin-bottom: 10px;
  }
  .bold {
    font-weight: bold;
  }
  .content {
    text-indent: 25px;
  }
  .content em {
    font-style: normal;
    text-decoration: underline;
  }
  .content b {
    color: #333;
    text-decoration: underline;
  }
  .sub-title {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
  }
}
</style>
